import styled from 'styled-components';

export const Container = styled.span`
  & > span {
    margin: 0 8px;
  }
`;

export const Loading = styled.span`
  font-size: 14px;
  line-height: 14px;
`;
