import React, { useState, useEffect } from 'react';
import * as S from './CountDown.styles';
import { convertTZ } from '../../utils/datetime';

const leadingZero = input => {
  if (String(input).length === 1) {
    return '0' + String(input);
  }

  return String(input);
};

const CountDown = props => {
  const {
    format,
    endMonth,
    endDay,
    endYear,
    endHour,
    endMinutes,
    endTimezone,
    timeoutString,
    customText
  } = props;
  const [intervalState, setIntervalState] = useState([
    0,
    '00',
    '00',
    '00',
    '00',
    true
  ]);
  const defaultTZ = endTimezone ?? 'Europe/London';
  const endDate = convertTZ(
    `${endMonth}/${endDay}/${endYear} ${endHour}:${endMinutes}:00 ${defaultTZ}`,
    defaultTZ
  );

  const countDownEndTime = new Date(endDate).getTime();

  useEffect(() => {
    if (intervalState[0] >= 0) {
      const timer = setTimeout(() => {
        const currentDate = convertTZ(new Date(), defaultTZ);
        const currentTime = new Date(currentDate).getTime();
        const intervalTime = countDownEndTime - currentTime;

        const days = leadingZero(
          Math.floor(intervalTime / (1000 * 60 * 60 * 24))
        );
        const hours = leadingZero(
          Math.floor((intervalTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        const minutes = leadingZero(
          Math.floor((intervalTime % (1000 * 60 * 60)) / (1000 * 60))
        );
        const seconds = leadingZero(
          Math.floor((intervalTime % (1000 * 60)) / 1000)
        );

        const init = intervalState[5];
        if (init) {
          setTimeout(() => {
            setIntervalState([
              intervalTime,
              days,
              hours,
              minutes,
              seconds,
              false
            ]);
          }, 1000);
        }

        setIntervalState([intervalTime, days, hours, minutes, seconds, init]);
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const getLabels = () => {
    const [, dd, hh, mm, ss] = intervalState;
    // labels
    const ddLabel = dd > 1 ? 'days' : 'day';
    const hhLabel = hh > 1 ? 'hours' : 'hour';
    const mmLabel = mm > 1 ? 'minutes' : 'minute';
    const ssLabel = ss > 1 ? 'seconds' : 'second';

    return {
      ddLabel,
      hhLabel,
      mmLabel,
      ssLabel
    };
  };

  const CustomText = () => {
    const [, dd, hh, mm, ss] = intervalState;
    let currentText = customText;
    currentText = currentText?.replace(`[DD]`, dd);
    currentText = currentText?.replace(`[HH]`, hh);
    currentText = currentText?.replace(`[MM]`, mm);
    currentText = currentText?.replace(`[SS]`, ss);

    const { ddLabel, hhLabel, mmLabel, ssLabel } = getLabels();
    currentText = currentText?.replace(`[DDLABEL]`, ddLabel);
    currentText = currentText?.replace(`[HHLABEL]`, hhLabel);
    currentText = currentText?.replace(`[MMLABEL]`, mmLabel);
    currentText = currentText?.replace(`[SSLABEL]`, ssLabel);

    return <div dangerouslySetInnerHTML={{ __html: currentText }} />;
  };

  const Output = () => {
    const [ii, dd, hh, mm, ss, init] = intervalState;

    if (ii > 0) {
      switch (format) {
        case 'CUSTOM-TEXT':
          return <CustomText />;
        case 'DD:HH:MM:SS':
          return (
            <>
              <span>{dd}</span>:<span>{hh}</span>:<span>{mm}</span>:
              <span>{ss}</span>
            </>
          );
        case 'DD:HH:MM':
          return (
            <>
              <span>{dd}</span>:<span>{hh}</span>:<span>{mm}</span>
            </>
          );
        case 'HH:MM':
          return (
            <>
              <span>{hh}</span>:<span>{mm}</span>
            </>
          );
        case 'MM:SS':
          return (
            <>
              <span>{mm}</span>:<span>{ss}</span>
            </>
          );
        default:
        case 'HH:MM:SS':
          return (
            <>
              <span>{hh}</span>:<span>{mm}</span>:<span>{ss}</span>
            </>
          );
      }
    } else {
      if (init) {
        return <S.Loading>loading...</S.Loading>;
      } else {
        return timeoutString || 'Promo Ended';
      }
    }
  };

  return (
    <S.Container>
      <Output />
    </S.Container>
  );
};

export default CountDown;
